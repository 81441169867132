@import "../imports";

#banner .paginator {
    top: calc(50% - 23px - 40px + 13px);
}
#banner #section-banner .reg-banner .bg .container .tag{
    @media(max-width:768px){
        font-size: 12px !important;
        margin-bottom: 0 !important;
    }
}
/*******************************************************************************/
#banner{
    position: relative;

    @media(max-width:1024px){
        margin-top: 80px;
    }

    @media(max-width:760px){
        margin-top: 60px;
    }
    
    #section-banner{
        background-color:#fff;
        width:100%;
        overflow:hidden;
        margin: 0;
    
        .slick-arrow{
            
        }
    
        .slick-dots{
            display: block;
            margin: -40px auto 40px;
            width: fit-content;
            padding-left:0px;
            z-index: 94;
            position: relative;
            @media(max-width:767px){
                margin-top: -20px;
            }
    
            li{
                display:inline;
                float:left;
                margin-left:2px;
                margin-right:2px;
                border-radius:50%;
                overflow:hidden;
                border-color: #fff;
                border-radius: 100%;
                    
                @media(max-width:767px){
                    border-radius:0%;
                }
    
                &.slick-active{
                    button{
                        background-color:#fff;
                    }
                }
    
                button{
                    background-color:transparent;
                    width:21px;
                    height:21px;
                    float:left;
                    overflow:hidden;
                    border-radius:100%;
                    text-indent:-9999px;
                    border:1px solid #fff;
                    transition:all 0.4s;
                    
    
                    @media(max-width:767px){
                        padding:0px !important;
                        width:15px !important;
                        height:15px;
                    }
                    &:focus{
                        border-color:$cor1;
                        background: transparent;
                        outline: none;
                    }
                }
            }
        }
    
        .reg-banner{
            background-color:#fff;
            background-size:cover;
            background-repeat:no-repeat;
            background-position: center center;
            width:100%;
            float:left;
            overflow:hidden;
    
            .bg{
                background:rgba(#000000, 0.37);
                width:100%;
                /*height:788px;*/
                height: 628px;
                float:left;
                overflow:hidden;

                
                @media(max-width:1400px){
                    padding-top: 50px;
                    height: 538px;
                }
    
                @media(max-width:1199px){
                    padding-top: 0px;
                    height:400px;
                }

                @media(max-width:760px){
                    height:250px;
                }

                .container{
                    .tag{
                        font-weight: bold;
                        font-size: 26px;
                        line-height: 36px;
                        text-align: center;
                        color: #f5e8df;
                        display: block;
                        margin-bottom: 16px;

                        @media(max-width:1199px){
                            font-size: 20px;
                            line-height: initial;
                            margin-bottom: 7px;
                        }
                        
                        @media(max-width:767px){
                            padding-bottom:10px;
                        }
                    }

                    h2{
                        border-radius: 8px;
                        /*width:50%;*/
                        color:#fff;
                        text-transform: uppercase;
                        padding:0 20px;
                        overflow:hidden;
                        color:#fff;
                        font-size:42px;
                        font-weight: bold;
                        position:relative;
                        z-index:20;
                        margin: 0;
                        text-shadow: 0px 0px 4px rgba(4,0,0,30%);
    
                        @media(max-width:1400px){
                            font-size:34px;
                        }

                        @media(max-width:1199px){
                            font-size: 30px;
                        }

                        @media(max-width:992px){
                            font-size: 20px;
                            line-height: 20px;

                        }
    
                        @media(max-width:767px){
                            font-size: 19px;
                            line-height: 25px;
                            text-align: center;
                        }
                    }
    
                    .btn-saiba-mais{
                        /*
                        background-color:$cor2;
                        float:right;
                        margin-top:20px;
                        color:#fff;
                        font-size:14px;
                        padding:15px 30px;
                        text-transform:uppercase;
                        border-radius:0px;
                        transition:all 0.4s;
                        position:relative;
                        z-index:20;
                        border-radius: 8px;
                        */
                        color:#fff;
                        font-size:20px;
                        text-shadow: 0px 0px 4px rgba(4,0,0,30%);
                        padding-top: 13px;
                        margin: 0 auto;
                        text-align: center;
                        display: block;
                
                        @media(max-width:1199px){
                            font-size: 17px;
                            padding-top: 3px;
                        }
    
                        @media(max-width:768px){
                            float: initial;
                            font-size: 12px;
                            padding: 8px 10px;
                            display: block;
                            width: fit-content;
                            margin: 0px auto 0px;
                        }
    
                        /*
                        &:hover{
                            background-color:$cor1;
                        }
                        */
    
                        i{
                            margin-left:10px;
                        }
                    }
                }
            }
        }
    }

    .paginator{
        position:absolute;
        width: 100%;
        top: calc(50% - 23px - 40px);
        margin-bottom: 20px;
        padding-top: 32px;

        ul{
            display: flex;
            justify-content: space-between;
            
            @media(max-width:760px){
                padding-left:0px;
            }
        }
      
        li{
            margin-top: 20px;
            position: relative;
            float: left;
        
            @media(max-width:760px){
                margin-top: 0px;
            }

            &.prev{
                display: block;
                height: 40px;
                width: 31px;
                background: url('../img/back.png') no-repeat;

                @media(max-width:760px){
                    width: 17px;
                    margin-left:10px;
                }
            }
        
            &.next{
                display: block;
                height: 40px;
                width: 31px;
                background: url('../img/next.png') no-repeat;

                @media(max-width:760px){
                    width: 17px;
                    margin-right:10px;
                }
            }
            
            svg{
                cursor: pointer;

                @media(max-width:760px){
                    width:17px;
                }
            }
        }
    }
}
#section-video{
    background-color: $cor-principal-dark;
    padding: 52px 0;

    @media(max-width:1199px){
        padding: 0px 0px 50px;
    }

    @media(max-width:992px){
        padding: 0px 0px 0px;
    }

    .inner-section-video{
        background: linear-gradient(90deg, #fff 0%, #fff 50%, transparent 50%, transparent 100%);

        @media(max-width:1024px){
            background: #fff;
            padding-top: 40px;
        
            .row{
                display: block;
            }
        }

        @media(max-width:760px){
            padding: 5px 8px;
        }

        
        .link-video{
            position: relative;
            display: block;
            margin: 38px 0 38px -23px;

            @media(max-width:1199px){
                margin: 0 auto 40px;
                max-width: 430px;
            }

            @media(max-width:760px){
                margin: 0 auto 20px;
            }

            &::before{
                content:"";
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.48);
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
            }
            img{
                max-width: 668px;
                @media(max-width:1024px){
                    max-width: 100%;
                   
                }
            }
            svg{
                position: absolute;
                left: 50%;
                top: 50%;
                margin: -49px 0 0 -49px;
                z-index: 2;
                transition: all 0.4s;
            }
            &:hover{
                svg{
                    margin: -100px 0 0 -100px;
                    width: 200px;
                    height: 200px;
                }
            }
        }
        .box-texto{
            font-weight: normal;
            font-size: 15px;
            line-height: 21px;
            text-align: left;
            color: #fff;

            @media(max-width:992px){
                color: $cor-principal-darker;
            }
            
            @media(max-width:760px){
                font-size: 14px;
                line-height: 19px;
            }

            a{
                margin: 0 auto;
                display: table;
            }
        }
    }
    .box-video{

    }
}
#section-slogan, .section-slogan{
    width:100%;
    overflow:hidden;
    padding: 35px 0;

    @media(max-width:1199px){
        padding-top: 30px;
        padding-bottom: 30px;
    }

    @media(max-width:992px){
        padding-top: 50px;
        padding-bottom: 16px;
    }

    @media(max-width:480px){
        padding:20px 0;
    }

    h2{
        font-weight: bold;
        font-size: 32px;
        text-align: center;
        color: #314839;
        margin-bottom:0px;

        @media(max-width:1400px){
            font-size:25px;
        }

        @media(max-width:1199px){
            font-size: 20px;
        }

        @media(max-width:760px){
            padding-top: 30px;
            line-height: 30px;
            font-size: 22px;
            
        }

        span{
            background-color:$cor2;
            color:#fff;
            padding:10px;

            @media(max-width:1199px){
                display: inline-block;
                margin-top: 10px;
            }
        }
    }
}

#section-slogan, .section-slogan{
    h2{
        @media(max-width:760px){
            padding-top: 0px;
            line-height: 28px;
            font-size: 20px;
        }
    }
}

.parallax{
    height: 500px;
    background-size: cover !important;
    background-attachment: fixed !important;
    background-position: center !important;
    background-image:url(../img/img-igreja.jpg);
    
    @media(max-width:1400px){
        height: 400px;
    }

    @media(max-width:768px){
        height: 300px;
    }
    
    @media(max-width:480px){
        background-position: center !important;
        background-attachment:scroll !important;
        height: 200px;   
    }

    img{
        height:70% !important;
    }

    &.parallax__gif{
        
        background-repeat: no-repeat;
        background-position-x: right !important;     
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}
#section-destaque{
    position: relative;
    .letra-m{
        position: absolute;
        top: 68px;
        left: 50%;
        width: 540px;
        margin-left: -385px;
        
        @media(max-width:1440px){
            width: 530px;
            margin-left: -377px;            
        }

        @media(max-width:1199px){
            width: 360px;
            margin-left: -257px;
        }

        @media(max-width:992px){
            width: 40%;
            margin-left: -44%;
        }

        @media(max-width:480px){
            margin: 0;
            max-width: 252px;
            width: 100%;
            left: 50%;
            margin-left: -125px;
            margin-top: -30px;
        }
    }

    .letra-u{
        position: absolute;
        bottom: 0;
        margin-bottom: 80px;
        left: 50%;
        margin-left: -291px;
        
        @media(max-width:1440px){
            width: 500px;
            margin-left: -250px;
        }
        
        @media(max-width:1024px){
            width: 400px;
            margin-left: -200px;
            margin-bottom: 20px;
        }

        @media(max-width:480px){
            margin: 0;
            max-width: 100%;
            width: 100%;
            left: 0;
        }
    }

    .container-plus{
        .bloco-2-itens{
            display: flex;
            flex-wrap: wrap;
            background-color: $cor-principal-light;
            align-items: center;
            width: 100%;
            position: relative;

            @media(max-width:480px){
                display: block;
            }
            .box-img{
                min-height: 435px;
                background-position:center center;
                background-size:cover;
                background-repeat:no-repeat;

                @media(max-width:1400px){
                    min-height: 345px;
                }

                @media(max-width:768px){
                    width: 50%;
                }
                
                @media(max-width:480px){
                    width: 100%;
                    min-height: 280px;
                }
            }

            .box-txt{
                @media(max-width:768px){
                    width: 50%;
                }
                @media(max-width:480px){
                    width: 100%;
                }
                div{
                    width: 600px;
                    max-width: 100%;
                    
                    p{
                        
                        text-align: left;
                        color: #fff;
                        float: right;
                        span{
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 27px;
                        }

                        @media(max-width:1024px){
                            /*
                            float: left;
                            margin-left: 40px;
                            */
                        }
                        @media(max-width:768px){
                            padding: 0 20px;
                            br{
                                display: none;
                            }
                        }
                        @media(max-width:480px){
                            float: none;
                            padding: 30px 10px;
                            margin-bottom: 0;
                        }
                    }
                }
                
            }
        }
    }
    .bloco-3-itens{
        background-color: $cor-principal-light;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .box-igreja{
            background-image:url(../img/img-igreja.jpg);
            background-position:center center;
            background-size:cover;
            background-repeat:no-repeat;
            height: 100%;
        }
        
        .box-img{
            height: 485px;;
            background-position:center center;
            background-size:cover;
            background-repeat:no-repeat;
        
            @media(max-width:1199px){
                height: 345px;
            }

            @media(max-width:992px){
                width: 100%;
                height:395px;
                min-height:auto;
            }

            @media(max-width:480px){
                width: 100%;
                height:300px;
            }
        }

        .box-txt{
            @media(max-width:1199px){
                padding-right: 30px;
            }

            @media(max-width:760px){
                padding-right:0px;
                padding-top:20px;
                padding-bottom:20px;
            }            

            > div{
                padding: 0 60px;

                @media(max-width:1199px){
                    padding: 0 40px 0px 0px;
                }
                
                @media(max-width:1024px){
                    padding: 20px 20px;
                }
    
                @media(max-width:768px){
                    p br{
                        display: none;
                    }
                }
                
                span{
                    font-size:20px;
                    font-weight:bold;
                    margin-bottom: 20px;
    
                    @media(max-width:1199px){
                        font-size: 18px;
                        margin-bottom: 10px;
                    }                    

                    @media(max-width:760px){
                        text-align:center;
                    }
                }

                p{
                    @media(max-width:1199px){
                        font-size: 13px;
                        line-height: 21px;
                    }     

                    @media(max-width:760px){
                        text-align:center;
                    }
                }
            }

            font-weight: 500;
            font-size: 15px;
            line-height: 27px;
            text-align: right;
            color: #fff;
            
            span{
                text-align: right;
                text-transform: uppercase;
                display: block;
            }
            
            a{
                margin: 10px 0 0;

                @media(max-width:760px){
                    display: block;
                    margin: 20px auto 0px;
                    width: 100%;
                    max-width: 180px;
                }
            }
            
        }
        .col-6{
            @media(max-width:480px){
                width: 100%;
                background-color: $cor-principal-dark;
            }
        }
        .box-entrada{
            background-image:url(../img/img-entrada.jpg);
            background-position:center center;
            background-size:cover;
            background-repeat:no-repeat;
            height: 100%;
        }
        @media(max-width:767px){
            .row{
                display: block;
                .col{
                    width: 100%;
                    > div{
                        min-height: 400px;

                    }
                }
            }
        }
    }
    .texto-direita{
        padding: 20px 40px 20px 80px;

        @media (max-width: 1400px){
            padding: 20px 0px 20px 60px;
        }

        @media (max-width: 1199px){
            padding: 20px 40px 20px 50px;
        }

        @media(max-width: 992px){
            padding-left: 40px;
        }

        @media (max-width: 767px) {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 21px;
            padding-bottom:40px;
        }

        span{
            padding-top: 1em;
            text-align: left;
            color: #fff;
            text-transform: uppercase;
            display: block;
            margin-bottom: 20px;
            font-weight: bold;
            font-size: 22px;

            @media (max-width: 1199px){
                margin-bottom: 10px;
                font-size: 19px;
            }

            @media (max-width: 760px){
                text-align:center;
            }
        }

        p{
            text-align: left;
            font-size: 15px;
            padding: 0 !important;

            @media (max-width: 1400px){
                font-size: 14px;
            }

            @media (max-width: 1199px){
                font-size: 13px;
            }

            @media (max-width: 760px){
                text-align:center !important;
            }
        }
    }
}
#section-rota-dos-ipes{
    background-color: $cor-principal-light;
    
    @media (max-width: 992px){
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom:70px;
    }

    h2{
        color: #fff;
        text-align: center;
        font-weight:bold;
        font-size: 36px;
        margin-bottom: 12px;
        width:100%;
        float:left;
        padding-top:20px;

        @media(max-width:1400px){
            font-size: 30px;
            margin-bottom: 9px;
        }

        @media(max-width:480px){
            font-size: 26px;
            line-height: 26px;
        }
    }

    h3{
        color: #fff;
        text-align: center;
        font-weight: 300;
        font-size: 22px;
        margin-bottom: 36px;

        @media(max-width:1400px){
            font-size: 18px;
            margin-bottom: 26px;
        }
        
        @media(max-width:480px){
            font-size: 18px;
            line-height: 24px;
        }
    }
    
    .imagens{
        position: relative;
        z-index: 1;

        .bg-esq{
            width: 600px;
            height: 517px;
            border-radius: 220px 0px 0px 0px;
            background: #fff;
            opacity: 0.07;
            
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;

        }
        .bg-dir{
            width: 600px;
            height: 517px;
            border-radius: 0px 0px 220px 0px;
            background: #fff;
            opacity: 0.07;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: -1;
        }
        .logo{
            max-width: 140px;
            /*
            POSSIVELMENTE VOLTARÁ A TER ESSA FORMATAÇÃO, POR ISSO O COMENTÁRIO
            
            position: absolute;
            left: 50%;
            bottom: 0;
            margin: 0 0 54px -70px;
            */
            z-index: 2;
            border-radius:14px;
            margin: 0 auto;
            display: table;

            @media(max-width:1400px){
                /*
                max-width: 130px;
                left: 26%;
                margin: 0 0 14px -60px;
                */
                margin-bottom: 10px;

                
            }            

            @media(max-width:1199px){
                max-width: 90px;
                margin: 0 auto 10px;
            }

            @media(max-width:1199px){
                max-width: 120px;
                margin: 0 auto 10px;
            }

            @media(max-width:480px){
                margin: 0 auto 10px;
            }
        }

        .lista{
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;

            @media(max-width:768px){
                border-radius: 120px 0px 120px 0px;
            }
            .item-img{
                width: 100%;
            
                
                @media(max-width:992px){
                    display: block;
                    margin: 0px auto;
                    max-width: 400px;
                }

                @media(max-width:480px){
                    width: 100%;
                }
                
                img{
                    width: 100%;
                    @media(max-width:1400px){
                        height: 520px;
                        object-fit: cover;
                    }
                    @media(max-width:768px){
                        height: auto;
                        margin-top: 10px;
                    }
                }
            }
        }
        
    }
    p{
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        color: #fff;
        max-width: 965px;
        margin: 35px auto 0;

        @media(max-width:1400px){
            font-size: 14px;
            margin: 0px auto 0;
        }

        @media(max-width:480px){
            margin-top: 0px;
        }
    }
}
#section-preservacao{
    background: linear-gradient(180deg, $cor-principal-light 0, $cor-principal-light 24px, $cor-principal-lighter 24px, $cor-principal-lighter 100%);

    .content{
        display: flex;
        align-items: center;
        @media(max-width:992px){
            display: block;
            img{
                width: 100%;
                margin-top: 20px;
            }
        }
        .box-txt{
            span{
                padding-right: 42px;
                h2{
                    color: $cor-principal-dark;
                    text-align: left;
                    font-weight: 300;
                    font-size: 38px;
                    line-height: 49px;
                    text-align: left;
                    margin-bottom: 27px;
                    margin-top: 47px;

                    @media(max-width:992px){

                        margin-top: 0;
                        padding-top: 50px;

                        br{
                            display: none;
                        }
                    }
                    @media(max-width:480px){
                        font-size: 30px;
                        line-height: 35px;
                    }
                }
                p{
                    color: $cor-principal-darker;         
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: left;
                    margin-bottom: 25px;
                    @media(max-width:480px){
                        br{
                            display: none;
                        }
                    }
                }
            }
            
        }
    }
}
#section-localizacao{
    padding:40px 0 0 0;

    h2{
        color: $cor-principal-dark;
        text-align: center;
        font-weight:bold;
        font-size: 36px;
        margin-bottom: 12px;

        @media(max-width:1400px){
            font-size: 30px;
            margin-bottom: 8px;
        }

        @media(max-width:1199px){
            font-size: 25px;
            margin-bottom: 0px;
        }

        @media(max-width:480px){
            font-size: 25px;
            line-height: 31px;
        }
    }

    h3{
        color: $cor-principal-darker;
        text-align: center;
        font-weight: 300;
        font-size: 22px;
        margin-bottom: 36px;

        @media(max-width:1199px){
            font-size: 19px;
            margin-bottom: 26px;
        }

        @media(max-width:480px){
            font-size: 17px;
            line-height: 22px;
            padding-top: 6px;
        }
    }

    .lista-destaque{
        display: flex;
        flex-wrap: wrap ;
        > div{
            margin-bottom: 10px;
        }
        a{
            display: block;
            margin: 0 5px;
            position: relative;
            transition:all 0.4s;
            overflow: hidden;
            img{
                width: 100%;
            }
            span{
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: #0F0F0F;
                font-weight: bold;
                font-style: italic;
                font-size: 15px;
                line-height: 25px;
                text-align: center;
                color: #ddd;
                padding: 7px 11px;
                font-style: normal;

            }
            &:hover{
                border-radius: 30px 0;
            }
        }
    }
}

#section-embaixadores{
    background-color: $cor-secundaria-lighter;
    width:100%;
    float:left;
    overflow:hidden;
    padding: 60px 0 80px;

    @media(max-width: 1400px){
        padding: 60px 0 60px;
    }

    @media(max-width:767px){
        padding-top: 30px;
        padding-bottom: 40px;
    }

    h2{
        font-weight: bold;
        font-size: 32px;
        text-align: center;
        color: $cor-secundaria-dark;
        margin-bottom:15px;
        line-height: normal;

        @media (max-width: 1400px) {
            font-size: 26px;
            margin-bottom: 10px;
        }

        @media (max-width: 1010px) {
            font-size: 60px;
        }

        @media(max-width:992px){
            font-size: 27px;
            margin-bottom: 14px;
            margin-top: 0px;
        }

        @media(max-width:760px){
            font-size: 24px;
            margin-bottom: 14px;
            margin-top: 20px;
        }
    }

    p{
        @media(max-width:760px){
            font-size: 14px;
        }
    }

    .slick-dots{
        display: block;
        margin: 30px auto 20px;
        width: fit-content;
        padding-left:0px;
        z-index: 94;
        position: relative;
        bottom: 0;

        li{
            display:inline;
            float:left;
            margin-left:2px;
            margin-right:2px;
            border-radius:50%;
            overflow:hidden;
                
            

            &.slick-active{
                button{
                    background-color:#fff;
                }
            }

            button{
                background-color:#656565;
                width:12px;
                height:14px;
                float:left;
                overflow:hidden;
                border-radius:6px;
                text-indent:-9999px;
                border:1px solid #656565;
                transition:all 0.4s;
            }
        }
    }

    .btn-saiba-mais{
        background-color:$cor2;
        margin-top:11px;
        color:#fff;
        font-size:14px;
        padding:12px 20px;
        transition:all 0.4s;
        border-radius: 0;
        margin: 24px auto 0;
        display: table;
        position: relative;

        @media(max-width:1199px){
            font-size: 13px;
            padding: 12px 19px;
        }

        @media(max-width:992px){
            margin-top: 1em;
        }

        @media(max-width:767px){
            white-space: initial;
        }

        &:hover{
            background-color:$cor1;
        }

        i{
            margin-left:10px;
        }
    }
}

.reg-depoimento{
    width:100%;
    float:left;
    overflow:hidden;
    /*margin-bottom:30px;*/

    @media(max-width:1199px){
        margin-bottom:30px;
    }
    
    .dados{
        background-color: $cor-secundaria-light;
        float: none;
        width:100%;
        height:286px;
        padding:10px 30px 50px 30px;
        overflow:hidden;
        border-top-left-radius:70px;
        border-bottom-right-radius:70px;

        @media(max-width:1400px){
            height: 266px;
            padding: 32px 30px 50px 30px;
            border-top-left-radius: 39px;
            border-bottom-right-radius: 30px;
        }

        @media(max-width:1199px){
            height: 236px;
            padding: 20px 30px 30px 30px;
            border-top-left-radius: 40px;
            border-bottom-right-radius: 40px;
        }

        @media(max-width:992px){
            margin-top: 2em;
        }

        @media(max-width:767px){
            height: 246px;
        }

        .center{
            width:100%;
            float:left;
            overflow:hidden;
        
            .init{
                float:left;
            }

            p{
                width:100%;
                float:left;
                padding-top:10px;
                overflow:hidden;
                color:#746464;
                text-align:center;
                margin-bottom:10px;
                font-style: italic;
                font-size: 13px;
                font-weight: 600;
                line-height: 21px;

                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;

                @media(max-width:1199px){
                    font-size: 12px;
                }
            }

            .end{
                float:right;
            }
        }
    }
    
    .img{
        background-position:center center;
        background-size:cover;
        width:100px;
        overflow:hidden;
        border-radius:50%;
        border:2px solid #fff;
        background-color: #fff;
        
        float: none;
        margin: 0 auto;
        width: 146px;
        height: 146px;
        margin-top: -78px;
        
        @media(max-width:1400px){
            width: 100px;
            height: 100px;
            margin-top: -58px;
        }

        @media (max-width: 767px) {
            margin-top: -46px;
        }

        /*
        @media(max-width:1199px){
            width: 96px;
            height: 96px;
            float: left;
            margin-top: -14px;
        }

        @media(max-width:767px){
            width: 45px;
            height: 45px;
            margin-top: 10px;
        }
         */
    }
    
    .nome{
        width:calc(100% - 100px);

        float: none;
        padding: 9px 10px 0 10px !important;
        width: 100%;

        /*
        @media(max-width:1199px){
            width:calc(100% - 96px);
        }

        @media(max-width:767px){
            padding-left:10px;
        }
        */

        span{
            display: block;
            color: #181818;
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            padding-top:6px;
        }
        
        i{
            font-weight: normal;
            font-style: italic;
            font-size: 14px;
            letter-spacing: -0.02em;
            line-height: 23px;
            text-align: center;
            color: #181818;
            display: block;
            font-weight: 500;

        }
    }
}

#section-elementos{
        background: #F5E8DF;
    p{
        font-weight: 300;
        font-size: 22px;
        line-height: 31px;
        text-align: center;
        color: #5e5e5e;
        padding: 70px 0px;
        margin: 0;
        @media(max-width:1024px){
            padding: 40px 0;
            br{
                display: none;
            }
        }
        
    }
    
}
#section-ambientes{
    width:100%;
    float:left;
    padding: 0px 0 0;

    h2{
        font-weight: normal;
        font-size: 34px;
        text-align: center;
        color: $cor-principal-dark;
        margin-bottom: 20px;
        font-weight:bold;

        @media(max-width:1400px){
            font-size: 30px;
            margin-bottom: 10px;
        }
    
        @media(max-width:1199px){
            margin-bottom: 10px;
            font-size: 25px;
        }

        @media(max-width:1024px){
            margin-bottom: 20px;
        }

        @media(max-width:480px){
            font-size: 18px;
            line-height: initial;
            margin-bottom: 10px;
        }
    }

    h4{
        font-weight: 300;
        font-size: 33px;
        line-height: 49px;
        text-align: center;
        color: #5e5e5e;
        margin-bottom: 66px;
        text-transform: uppercase;

        @media(max-width:768px){
            br{
                display: none;
            }
        }

        @media(max-width:480px){
            font-size: 20px;
            line-height: 25px;
        }
    }

    .subtexto{
        margin-bottom: 46px;

        @media(max-width:1199px){
            margin-bottom: 36px;
            font-size: 16px;
        }

        @media(max-width:760px){
            font-size: 14px;
        }

        *{
            text-align: center;
        }
    }

    .lista-ambientes{
        background-color: $cor-secundaria-lighter;
        width:100%;
        float:left;
        padding:50px 0px 40px;

        .conjunto-ambiente{
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            @media(max-width:992px){
                margin-bottom:20px;
            }

            &:nth-child(odd){
                .item-ambiente__img{
                    order:1;
                }
                .item-ambiente__texto{
                    order:2;
                    text-align: left;
                }
            }
            &:nth-child(even){
                .item-ambiente__img{
                    order:2;
                }
                .item-ambiente__texto{
                    order:1;
                    p{
                        text-align: right;
                    }
                }
                @media(max-width:992px){
                    .item-ambiente__img{
                        order:1;
                    }
                    .item-ambiente__texto{
                        order:2;
                        text-align: center;
                    }
                }
            }
        
            img{
                width: 100%;
            }

            p{
                font-weight: normal;
                font-size: 16px;
                line-height: 26px;
                text-align: left;
                color: $cor-secundaria-dark;
                padding: 0 40px;
                max-width: 720px;
                display: table;
                margin: 0 auto;

                @media(max-width:992px){
                    padding: 20px;
                    text-align: center !important;
                    @media(max-width:480px){
                        br{
                            display: none;
                        }
                    }
                }   

                @media(max-width:760px){
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }
    }

    .btn{
        margin: 40px auto 20px;
        display: table;
    }
}


#section-gastronomia{
    padding: 50px 0 0;

    h3{
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        color: #5e5e5e;
        margin-bottom: 40px;
        
        @media(max-width:1400px){
            font-size: 22px;
            line-height: 22px;
            margin-bottom: 30px;
        }

        @media(max-width:480px){
            font-size: 17px;
            line-height: 23px;
        }
    }

    .lista-gastronomia{
        display: flex;
        flex-wrap: wrap;
        
        .item-gastronomia{
            width: 50%;
            
            @media(max-width:767px){
                width: 100%;
            }
            
            img{
                width: 100%;
                margin-bottom: 22px;
            }

            span{
                display: block;
                padding: 0 20px;
                
                strong{
                    font-weight: bold;
                    font-size: 22px;
                    letter-spacing: -0.01em;
                    line-height: 26px;
                    text-align: left;
                    color: #202020;
                    margin-bottom: 9px;
                    display: block;

                    @media(max-width:1400px){
                        font-size: 19px;
                        line-height: 18px;
                        margin-bottom: 0px;
                    }
                }

                p{
                    font-weight: 500;
                    font-size: 14px;
                    letter-spacing: 0.03em;
                    line-height: 19px;
                    text-align: left;
                    color: #424646;
                }
            }
            
        }
    }
    .btn{
        margin: 40px auto 51px;
        display: table;

        @media(max-width:1400px){
            margin: 10px auto 51px;
        }
    }
}

#section-apartamentos{
    width:100%;
    float:left;
    overflow:hidden;

    h2{
        font-weight:bold;
        font-size: 23px;
        text-align: center;
        color: #fff;
        padding: 9px 0 20px;

        @media(max-width:1400px){
            font-size: 21px;
        }

        @media(max-width:1199px){
            font-size: 18px;
        }

        @media(max-width:992px){
            font-size: 17px;
            line-height: initial;
        }

        @media(max-width:760px){
            padding-left: 24px;
            padding-right: 24px;
            padding-bottom: 20px;
        }
    }

    .box-apartamento-destaque{
        background: linear-gradient(180deg, $cor-principal-dark 0, $cor-principal-dark 50%, #fff 50%, #fff 100%);
        padding: 40px 0 10px;

        .box-imagem{
            width:100%;
            max-width:900px;
            padding: 17px;
            background: #fff;
            display:block;
            margin:0px auto;

            @media(max-width:1400px){
                max-width: 840px;
                padding: 14px;
            }

            @media(max-width:1199px){
                max-width: 700px;
            }

            @media(max-width:992px){
                width:100%;
                float:left;
            }

            .item-imagem{
                @media(max-width:992px){
                    width:100%;
                    float:left;
                }
            }

            img{
                width: 100%;
            }

            .texto{
                @media(max-width:992px){
                    width:100%;
                    float:left;
                    margin-top: 110px;
                }

                @media(max-width:760px){
                    margin-top: 100px;
                    padding-bottom: 30px;
                }

                p{
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 25px;
                    text-align: left;
                    color: $cor-principal-dark;
                    margin: 25px 0 0 0;
                    @media(max-width:992px){
                        order: 2;
                        margin: -90px 0 0 0;
                        z-index: 3;
                    }
                    @media(max-width:480px){
                        margin-top: 0;
                    }

                }
                .fundo{
                    width:100%;
                    max-width:500px;
                    height:150px;
                    float:right;
                    margin-top: -240px;
                    z-index: 2;
                    position: relative;
                    margin-right: -22px;
                    overflow:hidden;

                    @media(max-width:1400px){
                        max-width: 440px;
                        height: 140px;
                        margin-top: -230px;
                    }

                    @media(max-width:992px){
                        order: 1;
                        width: 100%;
                        margin-top: -110px;
                    
                        svg{
                            width: 100%;
                            transform: scale(1.3);
                            margin-top: 1em;
                        }
                    }


                    @media(max-width:480px){
                        margin-top: -268px;
                        height: 150px;
                        max-width: 100%;
                        margin-right: 0px;

                        span{
                            margin: 86px 0px 0 20px !important;
                            font-size: 19px !important;
                            line-height: 23px !important;
                        
                            br{
                                display: none;
                            }
                        }
                    }

                    span{
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin: 70px 0 0 47px;
                        display: block;
                        font-weight: normal;
                        font-size: 22px;
                        font-weight:bold;
                        text-align: left;
                        color: $cor-principal-dark;

                        @media(max-width:1400px){
                            margin: 60px 0 0 22px;
                            font-size: 21px;
                        }

                        @media(max-width:992px){
                            margin-top: 60px;
                        }
                    }
                }
            }
        }
    }
    .lista-imoveis{
        .container-plus{
            > .row{
                width: 100%;
                margin-left: 0px;
            }
        }

        .item-imovel{
            position: relative;
            display: block;
            margin-bottom: 10px;
            span{
                flex-direction: column;
                position: absolute;
                width: calc(100% - 100px);
                height: calc(100% - 100px);
                top: 50px;
                left: 50px;
                background: rgba(110, 128, 91, 0.69);
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                transition: all 0.4s;
                color: white;
                font-size: 14px;
                line-height:33px;
                
                i{
                    border-top: 2px solid white;
                }

                @media(max-width:768px){
                    left: 20px;
                    top: 20px;
                    width: calc(100% - 40px);
                    height: calc(100% - 40px);
                }
                i{
                    font-size: 17px;
                    line-height: 20px;
                    font-style: normal;
                    color:#fff;
                    display: block;
                    font-weight:bold;
                    padding: 5px 14px;
                }
            }
            &:hover{
                span{
                    background: rgba(110, 128, 91, 0.9);
                }
            }
        }
    }
    .btn-padrao__secundario{
        margin: 30px auto 61px;
        width: 300px;
        max-width: 100%;
        display: table;

        @media(max-width:480px){
            width: auto;
            margin: 20px auto 40px;
            display: block;
            max-width: 180px;
        }
    }
}

#section-responsabilidade{
    width:100%;
    float:left;
    overflow:hidden;
    padding:60px 0px;

    @media(max-width:1400px){
        padding: 59px 0px 70px;
    }

    @media(max-width:1199px){
        padding: 49px 0px 40px;
    }

    @media(max-width:760px){
        padding: 39px 10px 30px;
    }

    h3{
        font-weight: bold;
        font-size: 25px;
        text-align: center;
        padding-bottom:20px;
        color: #AF5F48;
  
        @media(max-width:1400px){
            font-size: 22px;
            padding-bottom: 20px;
        }

        @media(max-width:1199px){
            font-size: 19px;
            padding-bottom: 18px;
        }

        @media(max-width:992px){
            font-size: 22px;
        }

        @media(max-width:480px){
            font-size: 15px;
            line-height: 23px;
            padding-bottom: 15px;
        }
    }

    .lista-responsabilidades{
        .item-responsabilidade{
            @media(max-width:767px){
                width: 50% !important;
            }

            @media(max-width:568px){
                width: 100% !important;
            }
        }

        img{
            width: 100%;
        }

        strong{
            font-weight: bold;
            font-size: 18px;
            letter-spacing: -0.01em;
            line-height: 26px;
            text-align: left;
            color: #202020;
            margin: 17px 0 10px;
            display: block;

            @media(max-width:1199px){
                font-size: 15px;
                line-height: initial;
            }
        }

        p{
            font-size:14px;
            
            @media(max-width:1400px){
                font-size:13px;
            }

            @media(max-width:1199px){
                font-size:12px;
            }
        }
    }
}

#section-noticias{
    
    h2{
        font-weight: normal;
        font-size: 93px;
        line-height: 49px;
        text-align: center;
        color: #817457;
        text-transform: uppercase;
        display: block;
        
        @media(max-width:480px){
            font-size: 30px;
            line-height: 35px;
        }

    }
}

/*-----------------------------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------------------------*/


#section-icones{
    background-color:#F1F1F1;
    width:100%;
    float:left;
    padding-top:28px;
    padding-bottom:56px;
    overflow:hidden;

    @media(max-width:1199px){
        padding-top:20px;
        padding-bottom:30px;
    }

    @media(max-width:767px){
        padding-top: 15px;
        padding-bottom: 20px;
    }

    #slick-links{
        width:calc(100% + 10px);
        float:left;
        margin-left:-5px;
        overflow:hidden;
        z-index:20;

        .reg-link{
            width:100%;
            float:left;
            overflow:hidden;
            padding:12px 12px;
            transition:all 0.4s;
            border:1px solid transparent;
            position:relative;
            @media(max-width:480px){
                padding: 12px 6px;
            }

            &:hover{
                transform:scale(1.1);

                .icon{
                    background-color:$cor1;

                    i{
                        color:#fff;
                    }
                }
            }

            .icon{
                background-color:#fff;
                width:92px;
                height:92px;
                display:block;
                border-radius:50%;
                margin:0px auto;
                border:5px solid #F1F1F1;
                text-align:center;
                position:relative;
                z-index:9;
                transition:all 0.4s;

                i{
                    color:#000000;
                    font-size:30px;
                    transition:all 0.4s;
                }
            }

            .dados{
                background-color:#fff;
                width:100%;
                height:100px;
                float:left;
                padding:51px 20px 20px;
                overflow:hidden;   
                margin-top:-30px;
                border-radius:5px;
                @media(max-width:480px){
                    padding:51px 10px 20px;
                }
                
                p{
                    color:#232323;
                    font-size:14px;
                    text-align:center;
                    letter-spacing: -0.7px;
                    text-transform:uppercase;
                }
            }
        }

        .slick-dots{
            display: block;
            margin: 56px auto 40px;
            width: fit-content;
            padding-left:0px;
            z-index: 94;
            position: relative;
    
            li{
                display:inline;
                float:left;
                margin-left:2px;
                margin-right:2px;
                border-radius:50%;
                overflow:hidden;
                
                @media(max-width:767px){
                    border-radius:0%;
                }
    
                &.slick-active{
                    button{
                        background-color:#fff;
                    }
                }
    
                button{
                    background-color:#656565;
                    width:21px;
                    height:21px;
                    float:left;
                    overflow:hidden;
                    border-radius:100%;
                    text-indent:-9999px;
                    border:1px solid #656565;
                    transition:all 0.4s;

                    
                    &:focus{
                        border-color:#434343;
                        background: transparent;
                        outline: none;
                    }

                    @media(max-width:767px){
                        padding:0px !important;
                        
                    }
                }
            }
        }
    }
}


#section-home1{
    background-image:url(../img/bg-associado-home.png);
    background-position:center center;
    background-size:cover;
    background-repeat:no-repeat;
    width:100%;
    float:left;
    overflow:hidden;

    .bg{
        background:rgba($cor1, 0.37);
        width:100%;
        float:left;
        padding-top:100px;
        padding-bottom:90px;
        overflow:hidden;

        @media(max-width:1199px){
            padding-top:60px;
            padding-bottom:60px;
        }
        
        h2{
            width:100%;
            display:block;
            text-align:center;
            padding-bottom:30px;
            color:#fff;
            font-size:32px;
            font-weight:bold;

            @media(max-width:1199px){
                font-size:28px;
            }
        
            span{
                background-color:$cor1;
                padding:7px 10px;
            }
        }

        p{
            width:100%;
            max-width:800px;
            display:block;
            margin:0px auto 10px;
            text-align:center;
            color:#fff;
            font-size:14px;
        }
        
        .btn-saiba-mais{
            background-color:$cor2;
            display:block;
            margin:10px auto 0px;
            color:#fff;
            font-size:14px;
            padding:10px 20px;
            transition:all 0.4s;
            border-radius: 8px;
            display: table;
            color:#fff;

            &:hover{
                background-color:$cor1;
            }
        }
    }
}


#section-home2{
    background-image:url(../img/bg-institucional2.jpg);
    background-position:center center;
    background-size:cover;
    background-repeat:no-repeat;
    width:100%;
    float:left;
    overflow:hidden;

    .bg{
        background:rgba(#000, 0.75);
        width:100%;
        float:left;
        padding-top:100px;
        padding-bottom:90px;
        overflow:hidden;
        
        .box-video{
            border:6px solid #fff;
        }

        h2{
            width:100%;
            display:block;
            text-align:center;
            padding-top:10px;
            padding-bottom:30px;
            color:#fff;
            font-size:32px;
            font-weight:bold;

            @media(max-width:1199px){
                padding-top: 0px;
                padding-bottom: 24px;
                font-size: 30px;
            }
        
            span{
                background-color:$cor1;
                padding:7px 10px;
                @media(max-width:480px){
                    display: inline-block;
                }
            }
        }

        p{
            width:100%;
            max-width:800px;
            display:block;
            margin:0px auto 10px;
            text-align:center;
            color:#fff;
            font-size:14px;
        }
        
        .btn-saiba-mais{
            background-color:$cor2;
            display:block;
            width:fit-content;
            margin:0px auto;
            color:#fff;
            font-size:14px;
            padding:17px 58px;
            transition:all 0.4s;
            border-radius: 8px;

            &:hover{
                background-color:$cor1;
            }
        }
    }
}





#section-home-midias{
    background-color:#fff;
    width:100%;
    float:left;
    padding-top:60px;
    padding-bottom:60px;
    overflow:hidden;
    
    .slick-dots{
        display: block;
        margin: 20px auto 40px;
        width: fit-content;
        padding-left:0px;
        z-index: 94;
        position: relative;

        li{
            display:inline;
            float:left;
            margin-left:2px;
            margin-right:2px;
            border-radius:50%;
            overflow:hidden;
                
            

            &.slick-active{
                button{
                    background-color:#fff;
                }
            }

            button{
                background-color:#656565;
                width:12px;
                height:14px;
                float:left;
                overflow:hidden;
                border-radius:6px;
                text-indent:-9999px;
                border:1px solid #656565;
                transition:all 0.4s;

                
            }
        }
    }
    
    #box-youtube{
        margin-bottom:30px;
    }

    .header{
        width:100%;
        float:left;
        padding-bottom:10px;
        overflow:hidden;
    
        > i{
            float:left;
            font-size:33px;
        }
    
        > h2{
            float:left;
            font-size:21px;
            font-weight:800;
            text-transform:uppercase;
            padding: 3px 0px 0px 10px;
            margin:0px;

        }
        
        > a{
            background-color:$cor2;
            float:right;
            color:#fff;
            border: 0px;
            font-size: 17px;
            padding: 2px 6px 0px;
            line-height: 25px;
            transition:all 0.4s;
            border-radius:3px;

            &:hover{
                background-color:$cor1;
            }
        }
    }
    
    .conteudo{
        width:100%;
        float:left;
        overflow:hidden;
    
        .reg-noticia{
            float:left;
            margin-left:2px;
            margin-right:2px;

            &:hover{
                .img{
                    background-size:auto 140%;
                
                    .mascara{
                        background-color:rgba($cor2, 0.31);

                        i{
                            color:$cor1;
                        }
                    }
                }
            }

            .img{
                background-position:center center;
                background-size:auto 120%;
                width:100%;
                float:left;
                overflow:hidden;
                transition:all 0.4s;
                
                .mascara{
                    background-color:rgba(#BFAA71, 0.41);
                    width:100%;
                    height:153px;
                    float:left;
                    overflow:hidden;
                    color:#fff;
                    transition:all 0.4s;

                    @media(max-width:992px){
                        height: 194px;
                    }

                    @media(max-width:767px){
                        height:134px;
                    }
                
                    i{
                        font-size:49px;
                        transition:all 0.4s;
                    }
                }
            }

            .dados{
                width:100%;
                float:left;
                padding:10px 10px 0px 10px;
                overflow:hidden;
    
                h3{
                    width:100%;
                    float:left;
                    overflow:hidden;
                    font-size:14px;
                    color:$cor1;
                    text-align:center;
                }
            }
        }
    }
}

#section-home-instagram{
    background-color:#291E1E;
    width:100%;
    float:left;
    padding-top:60px;
    padding-bottom:60px;
    overflow:hidden;

    .header{
        width:100%;
        float:left;
        padding-bottom:10px;
        overflow:hidden;
    
        > i{
            float:left;
            font-size:33px;
            color:#fff;
        }
    
        > h2{
            color:#fff;
            float:left;
            font-size:21px;
            font-weight:800;
            text-transform:uppercase;
            padding: 3px 0px 0px 10px;
            margin:0px;

        }
        
        > a{
            background-color:$cor2;
            float:right;
            color:#fff;
            border: 0px;
            font-size: 17px;
            padding: 2px 6px 0px;
            line-height: 25px;
            transition:all 0.4s;
            border-radius:3px;

            &:hover{
                background-color:$cor1;
            }
        }
    }   

    #embed-instagram{
        width:100%;
        float:left;
        margin-top:15px;
        overflow:hidden;
    }
}





#section-parceiros,
#section-parceiros2{
    width:100%;
    float:left;
    padding-top:50px;
    padding-bottom:50px;
    overflow:hidden;

    h2{
        width:100%;
        float:left;
        padding-bottom:10px;
        color:$cor1;
        font-size:17px;
        text-align:center;
        text-transform:uppercase;        
    }

    .reg-slick-parceiro{
        a{
            padding:15px !important;
        }
    }

    #slick-parceiros,
    .slick-parceiros2{
        width:100%;
        float:left;
        overflow:hidden;

        .reg-slick-parceiro{
            width:100%;
            float:left;
            overflow:hidden;

            a{
                width:100%;
                max-width:242px;
                float:left;
                padding:15px;
                overflow:hidden;

                img{}
            }
        }

        .slick-arrow{
            display:none !important;
        }
    
        .slick-dots{
            display: block;
            margin: 35px auto 0px;
            width: fit-content;
            padding-left:0px;
            z-index: 94;
            position: relative;
    
            li{
                display:inline;
                float:left;
                margin-left:2px;
                margin-right:2px;
                border-radius:50%;
                overflow:hidden;
                
                
    
                &.slick-active{
                    button{
                        background-color:#fff;
                    }
                }
    
                button{
                    background-color:#656565;
                    width:12px;
                    height:14px;
                    float:left;
                    overflow:hidden;
                    border-radius:6px;
                    text-indent:-9999px;
                    border:1px solid #656565;
                    transition:all 0.4s;

                    
                }
            }
        }
    }

    .seta{
        width:10px;
        float:left;
        color:#bbbbbb;
        margin-top: 35px;

        @media(max-width:992px){
            margin-top: 41px;
        }

        @media(max-width:767px){
            width: 40px;
            margin-top: 19%;
        }

        i{
            @media(max-width:767px){
                font-size: 35px;
            }
        }
    }

    .slick-parceiros2{
        width:calc(100% - 20px);
        float:left;

        @media(max-width:992px){
            margin-bottom:30px;
        }

        @media(max-width:767px){
            width:100%;
            margin-bottom:50px;
        }
    }
}

#section-home-associados{
    width:100%;
    float:left;
    padding-top:80px;
    padding-bottom:50px;
    overflow:hidden;
    background: #E1E1E1;
    h2{
        font-size: 30px;
        color: #545454;
        font-weight: 800;
        margin-bottom: 45px;
    }
    .reg-associado{
        padding: 0 10px;
        img{
            border-radius: 5px;
            max-width: 100%;
            width: 100%;
            background-color: white;
        }
    }
    


    .slick-dots{
        display: block;
        margin: 47px auto 40px;
        width: fit-content;
        padding-left:0px;
        z-index: 94;
        position: relative;

        li{
            display:inline;
            float:left;
            margin-left:2px;
            margin-right:2px;
            border-radius:50%;
            overflow:hidden;

            &.slick-active{
                button{
                    background-color:#fff;
                }
            }

            button{
                background-color:#656565;
                width:21px;
                height:21px;
                float:left;
                overflow:hidden;
                border-radius:100%;
                text-indent:-9999px;
                border:1px solid #656565;
                transition:all 0.4s;


                &:focus{
                    border-color:#434343;
                    background: transparent;
                    outline: none;
                }
            }
        }
    }
} 
#slick-youtube{

    figure{
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(31, 41, 78, 0.6);
        }

        svg{
            position: absolute;
            top:50%;
            left: 50%;
            margin: -37px 0 0 -37px;
            transition: all 0.4s;
        }
        &:hover{
            svg{
                width: 100px;
                height: 100px;
                margin: -50px 0 0 -50px;
        
            }
        }
    }
    
    /* the slides */
  .slick-slide {
    margin: 0 10px;
  }
  /* the parent */
  .slick-list {
    margin: 0 -10px;
  }

    .slick-prev, .slick-next, fa-angle-left, fa-angle-next{
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 40%;
        display: block;
        width: 20px;
        height: 20px;
        padding: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background: transparent;
        font-family: "Font Awesome 5 Free";content: "";


        @media (max-width: 767px) {
            display: none;

        }
    }
    .slick-prev:hover,
    .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus{
        color: transparent;
        outline: none;
        background: transparent;
    }
    .slick-prev:hover:before,
    .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before{
        opacity: 1;
    }
    .slick-prev.slick-disabled:before,
    .slick-next.slick-disabled:before{
        opacity: .25;
    }
    .slick-prev:before,
    .slick-next:before{
        font-size: 40px;
        line-height: 1;
        opacity: .75;
        color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .slick-prev{
        left: -25px;
        @media (max-width: 1024px) {
            left: -13px;
        }
    }
    [dir='rtl'] .slick-prev{
        right: -25px;
        left: auto;
    }
    .slick-prev:before{

    }
    [dir='rtl'] .slick-prev:before{
        content: '→';
    }
    .slick-next{
        right: -25px;
        @media (max-width: 1024px) {
            right: -13px;
        }
    }
    [dir='rtl'] .slick-next{
        right: auto;
        left: -25px;
    }
    .slick-next:before{

    }
    [dir='rtl'] .slick-next:before {
        content: '←';
    }

    h1{
        color: white;
        font-weight: bold;
        font-size: 35px;
        max-width:1113px;
        margin: 0 auto;
    }

    .container{
        padding-bottom: 3em;
        padding-top: 2em;

        .videos-youtube{
            margin-right: .5em;
            margin-left: .5em;
        }

        &#slide-videos-youtube{
            figure{
                background: black;
                border: 5px solid white;

                img{
                    filter: opacity(0.4);
                    width: 14em;

                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }
            }
            .div-icon-play{
                width: 100%;
                float: left;
                margin-top: -111px;
                position: relative;
                z-index: 9;
                transition: 0.4s;
                color: white;
                text-align: center;

                @media (max-width: 992px) {
                    margin-top: -110px;
                }

                @media (max-width: 776px) {
                    margin-top: -85px;
                }

                @media (max-width: 575px) {
                    margin-top: -140px;
                }

                i{
                    border: 4px solid white;
                    padding: 1em;
                    font-size: 17px;
                    border-radius: 50px;
                    transition: 0.4s;

                    &:hover{
                        background: white;
                        color: black;
                    }
                }
            }
        }
    }
    
}

/* MODAL POLITICAS */
.embaixador-depo{
    background: #0f0e0ead;
    

    .modal-dialog{
        max-width: 90%;
        /*
        @media (max-width: 1024px) {
            width: 100% !important;
            max-width: fit-content;
        }
        @media (max-width: 578px) {
            padding-top: initial;
        }
        */
        
    }
    .modal-content{
        background-color: #DCB9A7;
        border-top-left-radius: 60px;
        border-bottom-right-radius: 22px;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border: none;

        .close-modal{
            float: right;
            margin-top: -120px;
            margin-right: -25px;
            color: #AF5F48;
            background: none;
            border: none;
            font-size: 70px;
            font-weight: 700;
            transition: 0.2s;

            @media (max-width: 578px) {
                position: absolute;
                right: 0;
                margin-top: -60px;
                margin-right: 0;
                float: none;
            }

            &:hover{
                font-size: 75px;
                color: darken(#AF5F48, 5%);
                margin-top: -123px;
            }
        }

        .modal-body{
            padding: 2em 1rem;

            .dados{
                background-color: $cor-secundaria-light;
                float: none;
                width:100%;
                height:336px;
                padding:30px 30px 50px 30px;
                overflow:hidden;
                border-top-left-radius:70px;
                border-bottom-right-radius:70px;

                .center{
                    width:100%;
                    float:left;
                    overflow:hidden;

                    .init{
                        float:left;
                    }


                    p{
                        width:100%;
                        float:left;
                        padding-top:10px;
                        overflow:hidden;
                        color:#746464;
                        text-align:center;
                        margin-bottom:10px;
                        font-style: italic;
                        font-size: 13px;
                        font-weight: 600;
                        line-height: 26px;
                    }

                    .end{
                        float:right;
                    }
                }
            }

            .img{
                background-position:center center;
                background-size:cover;
                overflow:hidden;
                border-radius:50%;
                border:2px solid #fff;
                background-color: #fff;

                float: none;
                margin: 0 auto;
                width: 186px;
                height: 186px;
            }

            .depoimento-texto{
                p, div, span{
                    color: #746464 !important;
                    font-style: italic;
                    font-size: 14px;

                    @media (max-width: 992px) {
                        text-align: center;
                    }
                }
            }

            .nome{
                float: none;
                padding: 9px 10px 0 10px !important;
                width: 100%;

                @media (max-width: 992px) {
                    margin-bottom: 2em;
                }

                span{
                    display: block;
                    color: #181818;
                    font-size: 20px;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: 600;
                }
                i{
                    font-style: italic;
                    font-size: 14px;
                    letter-spacing: -0.02em;
                    line-height: 23px;
                    text-align: center;
                    color: #181818;
                    display: block;
                    font-weight: 500;

                }
            }

            .video {
                width: 50%;
                float: right;
                margin: 0 0 0 10px;
                @media (max-width: 768px) {
                    width: 100%;
                }
                .inner-video{
                    position: relative;
                    padding-bottom: 56.25%; /* 16:9 */
                    height: 0;
                    width: 100%;
                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                      }
                }
                
            }
        }
    }
}


/* MODAL EMBAIXADORES */
#politicaPrivacidadeModal{
    background: #0f0e0ead;

    .modal-dialog{
        padding-top: 3em;
        
        
        @media (max-width: 578px) {
            padding-top: initial;
        }
    }

    .modal-content{
        background-color: #DCB9A7;
        border-top-left-radius: 60px;
        border-bottom-right-radius: 22px;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border: none;

        .close-modal{
            float: right;
            margin-top: -120px;
            margin-right: -25px;
            color: #AF5F48;
            background: none;
            border: none;
            font-size: 70px;
            font-weight: 700;
            transition: 0.2s;

            @media (max-width: 578px) {
                position: absolute;
                right: 0;
                margin-top: -60px;
                margin-right: 0;
                float: none;
            }

            &:hover{
                font-size: 75px;
                color: darken(#AF5F48, 5%);
            }
        }

        .modal-body{
            padding: 2em 1rem;

            .dados{
                background-color: $cor-secundaria-light;
                float: none;
                width:100%;
                height:336px;
                padding:30px 30px 50px 30px;
                overflow:hidden;
                border-top-left-radius:70px;
                border-bottom-right-radius:70px;

                .center{
                    width:100%;
                    float:left;
                    overflow:hidden;

                    .init{
                        float:left;
                    }


                    p{
                        width:100%;
                        float:left;
                        padding-top:10px;
                        overflow:hidden;
                        color:#746464;
                        text-align:center;
                        margin-bottom:10px;
                        font-style: italic;
                        font-size: 13px;
                        font-weight: 600;
                        line-height: 26px;
                    }

                    .end{
                        float:right;
                    }
                }
            }

            .img{
                background-position:center center;
                background-size:cover;
                overflow:hidden;
                border-radius:50%;
                border:2px solid #fff;
                background-color: #fff;

                float: none;
                margin: 0 auto;
                width: 186px;
                height: 186px;
            }

            .depoimento-texto{
                p, div, span{
                    color: #746464 !important;
                    font-style: italic;
                    font-size: 14px;

                    @media (max-width: 992px) {
                        text-align: center;
                    }
                }
            }

            .nome{
                float: none;
                padding: 9px 10px 0 10px !important;
                width: 100%;

                @media (max-width: 992px) {
                    margin-bottom: 2em;
                }

                span{
                    display: block;
                    color: #181818;
                    font-size: 20px;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: 600;
                }
                i{
                    font-style: italic;
                    font-size: 14px;
                    letter-spacing: -0.02em;
                    line-height: 23px;
                    text-align: center;
                    color: #181818;
                    display: block;
                    font-weight: 500;

                }
            }
        }
    }
}

.cookiealert {
    background: #AF5F48 !important;
    .acceptcookies {
        background: white;
        border: none;
        border-radius: 0;
        color: #AF5F48;
        font-weight: bold;

        &:hover {
            background: darken(white, 5%);
        }
    }
}

.close-modal-perso{
    float: right;
    margin-top: -120px;
    margin-right: -25px;
    color: #AF5F48;
    background: none;
    border: none;
    font-size: 70px;
    font-weight: 700;
    transition: 0.2s;

    @media (max-width: 578px) {
        position: absolute;
        right: 0;
        margin-top: -60px;
        margin-right: 0;
        float: none;
    }

    &:hover{
        font-size: 75px;
        color: darken(#AF5F48, 5%);
        margin-top: -123px;
    }
}

.modal-title-perso{
    text-transform: uppercase;
    color: #AF5F48;
    text-align: center;
    font-size: 34px;
    line-height: 32px;
    font-weight: 800;
    display: block;
}

.info-elemento {
    align-items: center;
    margin-bottom: -25px;

    @media (max-width: 991px) {
        margin-bottom: -53px;
    }

    .img-elemento-icon {
        width: 90px;
        height: 90px;
        background-position: bottom;
        background-size: unset;
        background-repeat: no-repeat;
        border-radius: 50px;
        border: 4px solid white;
        margin-right: -15px;
        position: absolute;

        @media (max-width: 1400px){
            width: 70px;
            height: 70px;
        }
    }

    .nome-elemento {
        background: white;
        width: 60%;
        border-radius: 30px;
        display: flex;

        @media (max-width: 1199px){
            width: 36%;
        }

        @media (max-width: 992px){
            width: 48%;
        }

        p {
            padding: 10px 20px;
            margin: 0;
            text-transform: uppercase;
            color: #7b7b7b;
            font-weight: bold;
        }
    }
}

#section-servicos{
    background-color:$cor-principal-light;
    width:100%;
    float:left;
    overflow:hidden;

    #home-servicos{
        width:100%;
        float:left;
        overflow:hidden;
        padding-left:120px;
        padding-right:120px;

        @media (max-width:992px){
            padding-left: 0px;
            padding-right: 0px; 
        }

        h3{
            font-weight: bold;
            font-size: 25px;
            text-align: center;
            color: #fff;
            margin-bottom: 15px;
            margin-top:60px;
            text-transform: uppercase;

            @media(max-width:1400px){
                font-size: 22px;
                margin-bottom: 15px;
                margin-top: 50px;
            }

            @media(max-width:992px){
                font-size: 24px;
            }

            @media(max-width:768px){
                br{
                    display: none;
                }
            }

            @media(max-width:480px){
                font-size: 17px;
                line-height: 22px;
            }
        }

        h4{
            font-size: 17px;
            line-height:25px;
            text-align: center;
            color: #fff;
            margin-bottom: 20px;

            @media(max-width:1400px){
                font-size: 16px;
                line-height: 23px;
            }

            @media(max-width:480px){
                font-size: 13px;
                line-height: 19px;
                margin-bottom: 0px;
            }
        }

        .subtexto{
            display:block;
            padding-left:10px;
            padding-right:10px;
            margin-bottom: 66px;
            color:#fff;

            
            @media(max-width:1400px){
                font-size: 15px;
            }

            *{
                text-align: center;

            }
        }

        ul{
            @media(max-width:480px){
                padding-left:20px;
            }
        }
    }
}

#section-implantacao{
    width:100%;
    float:left;
    overflow:hidden;
    padding-top:50px;
    
    h2{
        font-weight: normal;
        font-size: 34px;
        text-align: center;
        color: $cor-principal-dark;
        font-weight:bold;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 0;

        @media (max-width:1400px){
            font-size: 23px;
            padding-top: 0px;
            padding-bottom: 0px;
        }

        @media (max-width:1199px){
            font-size: 21px;
        }

        @media (max-width: 992px) {
            font-size: 21px;
            line-height: initial;
        }

        @media (max-width: 760px) {
            font-size: 19px;
        }
    }

    .subtitulo{
        font-weight: 300;
        font-size: 18px;
        line-height: 31px;
        text-align: center;
        color: #5e5e5e;
        margin: 5px 0 0;
        padding-bottom:0px;

        @media (max-width:1400px){
            font-size: 15px;
            line-height: 31px;
        }

        @media (max-width:1199px){
            font-size: 15px;
            line-height: 23px;
            padding-left: 100px;
            padding-right: 100px;
        }

        @media (max-width:992px){
            font-size: 16px;
            line-height: 23px;
            padding-left: 0px;
            padding-right: 0px;
            padding-top: 9px;
        }

        @media (max-width:760px){
            padding-bottom: 0px;
        }
    }

    .box-apartamento-destaque{
        padding: 5px 0 40px;
        
        @media (max-width:760px){
            background: #fff;
            padding: 0px 0 0;
        }

        .box-imagem{
            padding: 18px;
            background: #fff;
            width:100%;
            max-width:1000px;
            display:block;
            margin:0px auto;

            @media (max-width:1400px){
                padding: 15px;
                max-width: 890px;
            }

            @media (max-width:1199px){
                max-width: 750px;
            }
            
            @media (max-width:992px){
                overflow: hidden;
            }

            img{
                width: 100%;
            }

            .texto{
                @media (max-width:992px){
                    width: 100%;
                    float: left;
                    margin-top: 111px;
                }
            }

            #section-implantacao-img{
                .imagem{
                    div{
                        width: 100%;
                        padding-bottom: 40%;
                        margin: 0px auto;
                        background-size: cover;
                        background-position:center center;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }
}
@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham.eot');
    src: url('../fonts/gotham.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham.woff2') format('woff2'),
        url('../fonts/gotham.woff') format('woff'),
        url('../fonts/gotham.ttf') format('truetype'),
        url('../fonts/gotham.svg#gotham') format('svg');
    font-display: swap;

    font-family: 'Gotham';
    src: url("../fonts/gotham.eot");
    src: url("../fonts/gotham.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham.woff2") format("woff2"), url("../fonts/gotham.woff") format("woff"), url("../fonts/gotham.ttf") format("truetype"), url("../fonts/gotham.svg#gotham") format("svg");
    font-display: swap; 
}
